<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" :src="IndividualTraining"> </v-img>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="6">
          <p>
            Do you need more 1-on-1 training? If so, we have coaches who
            specialize in improving these skills:
          </p>
          <ul>
            <li>Hitting</li>
            <li>Fielding</li>
            <li>Pitching</li>
            <li>Base Running</li>
            <li>Catching</li>
          </ul>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Step 1: Assessment (1 hour)</h4>
          <p class="mt-2">
            We use this time to evaulate you as a player and more importantly
            learn more about you as a person.
          </p>
          <h4>Step 2: Start your Player Development Journey</h4>
          <p class="mt-2">
            Now it's time to get to work. Each session we will follow a plan
            specifically designed to help you improve as a player.
          </p>
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col cols="12" md="6">
          <h4>Session Options</h4>
          <ul class="mt-2">
            <li>$70.00 - 60 minutes</li>
            <li>$35.00 - 30 mintues</li>
          </ul>
          <p class="mt-2">
            Please arrive 15 minutes before each training session to complete
            the warmup.
          </p>
          <ContactButton title="Sign Me Up" />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col col="12">
          <h2>Check out our Instructors</h2>
          <h6>(Click on the player card for more information)</h6>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-card
            hover
            flat
            class="mx-auto"
            :to="{ name: 'AndersonMillerPage' }"
            max-width="250"
          >
            <PlayerCardMiller />
          </v-card>
        </v-col>

        <!-- <v-col cols="12" md="6" lg="4">
          <v-card
            hover
            flat
            class="mx-auto"
            :to="{ name: 'BruceBellPage' }"
            max-width="250"
          >
            <PlayerCardBell />
          </v-card>
        </v-col> -->

        <v-col cols="12" md="6" lg="4">
          <v-card
            hover
            flat
            class="mx-auto"
            :to="{ name: 'CaseyBrownPage' }"
            max-width="250"
          >
            <PlayerCardBrown />
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-card
            hover
            flat
            class="mx-auto"
            :to="{ name: 'GrantKnippPage' }"
            max-width="250"
          >
            <PlayerCardKnipp />
          </v-card>
        </v-col>



        <!-- <v-col cols="12" md="6">
          <v-card
            hover
            flat
            class="mx-auto"
            :to="{ name: 'AndersonMillerPage' }"
            max-width="250"
          >
            <v-img class="white--text align-end" :src="CardMiller"> </v-img>
          </v-card>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col>
          <v-img class="dark align-center mt-4" :src="PDTrain"> </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardMiller from '@/assets/images/CardMiller.svg'
import PDTrain from '@/assets/images/PDTrain.svg'
import IndividualTraining from '@/assets/images/IndividualTraining.svg'
import PlayerCardBell from '@/components/PlayerCardBell.vue'
import PlayerCardMiller from '@/components/PlayerCardMiller.vue'
import PlayerCardBrown from '@/components/PlayerCardBrown.vue'
import PlayerCardKnipp from '@/components/PlayerCardKnipp.vue'

export default {
  data() {
    return {
      CardMiller: CardMiller,
      PDTrain: PDTrain,
      IndividualTraining: IndividualTraining,
    }
  },
  components: {
    // PlayerCardBell,
     PlayerCardMiller, PlayerCardBrown, PlayerCardKnipp },
}
</script>

<style></style>
